import { FC } from 'react';

import { Heading, Stack } from '@sparky';
import { HeadingProps } from '@sparky/types';

interface Props {
  title: string;
  size?: HeadingProps['size'];
}

export const BrandBoxTitle: FC<Props> = ({ title, size = 'L' }) => (
  <Stack gap="4">
    <Heading as="h2" size={size} color="textInverted">
      {title}
    </Heading>
  </Stack>
);
