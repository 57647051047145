import RichText from '@components/RichText/RichText';
import { Checklist } from '@components/ui/Checklist/Checklist';
import BrandBox from '@custom-components/BrandBox/BrandBox';
import { useLinkComponent } from '@link';
import { wrap } from '@sitecore/common';
import { Placeholder } from '@sitecore/common';
import { HeroCardRebrandedRendering, Fields } from '@sitecore/types/HeroCardRebranded';
import { Stack, ButtonLink, Grid, Card, Box, Text, TextLink, Stretch, Bleed } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';
import { BoxProps, TextColors } from '@sparky/types';

type FooterTypes = Pick<Fields, 'footerLink' | 'footerText'>;
type FooterStyles = {
  footerBackgroundColor?: BoxProps['backgroundColor'];
  footerTextColor: keyof TextColors;
};
type ContentBlockTypes = Pick<Fields, 'content' | 'buttonLink' | 'link' | 'secondaryButtonLink' | 'uspList'>;
type Placeholders = Pick<HeroCardRebrandedRendering, 'placeholders'>;

const HeroCardRebranded: React.FC<HeroCardRebrandedRendering> = ({
  fields: { title, content, footerLink, footerText, buttonLink, link, secondaryButtonLink, uspList },
  placeholders,
  params,
}) => {
  const Link = useLinkComponent();
  const isDesktop = useMediaQuery('lg');

  const Footer: React.FC<FooterTypes & FooterStyles> = ({
    footerLink,
    footerText,
    footerTextColor,
    footerBackgroundColor,
  }) => {
    if (
      !((footerLink?.value?.href || footerText?.value) && footerBackgroundColor && footerTextColor) &&
      !footerText?.editable
    ) {
      return null;
    }

    return (
      <Box
        backgroundColor={footerBackgroundColor}
        borderBottomLeftRadius="m"
        borderBottomRightRadius="m"
        paddingX={{ initial: '6', md: '10' }}
        paddingY="3">
        <Stack alignX="center">
          <Stack.Item as="span">
            {footerText?.value || footerText?.editable ? (
              <Box as="span" paddingRight={footerLink.value.href || footerLink.editable ? 2 : 0}>
                <Text size="BodyS" display="inline" weight="bold" color={footerTextColor}>
                  {wrap(footerText)}
                </Text>
              </Box>
            ) : null}
            {footerLink?.value?.href || footerLink?.editable ? (
              <Text size="BodyS" display="inline">
                <Link editable={footerLink.editable} linkType={footerLink.value.linktype} linkValue={footerLink.value}>
                  <TextLink target={footerLink.value.target} color={footerTextColor}>
                    {footerLink.value.text}
                  </TextLink>
                </Link>
              </Text>
            ) : null}
          </Stack.Item>
        </Stack>
      </Box>
    );
  };

  const ContentBlock: React.FC<ContentBlockTypes & Placeholders> = ({
    content,
    buttonLink,
    link,
    placeholders,
    secondaryButtonLink,
    uspList,
  }) => (
    <Box borderRadius="m" padding={{ initial: '0', lg: '8' }} paddingTop={{ initial: '6', lg: '12' }}>
      <Stack gap="6">
        {(content.value || content.editable) && (
          <Text size={{ initial: 'BodyM', lg: 'BodyL' }}>{wrap(content, <RichText html={content.value} />)}</Text>
        )}
        {uspList?.value?.enum?.length && (
          <Checklist>
            {uspList.value.enum.map(usp => {
              return (
                <Checklist.Item alignY="start" key={usp.name}>
                  {usp.label}
                </Checklist.Item>
              );
            })}
          </Checklist>
        )}
        {!!placeholders?.['jss-hero-card-main']?.length && (
          <Box>
            <Placeholder name="jss-hero-card-main" />
          </Box>
        )}
        {((buttonLink.value.href && buttonLink.value.text) || buttonLink.editable) && (
          <Stack direction="row" gap="3">
            <Stack.Item grow={false}>
              <Stretch width={{ initial: true, lg: false }}>
                <Link editable={buttonLink.editable} linkType={buttonLink.value.linktype} linkValue={buttonLink.value}>
                  <ButtonLink target={buttonLink.value.target}>{buttonLink.value.text}</ButtonLink>
                </Link>
              </Stretch>
            </Stack.Item>
            {((secondaryButtonLink.value.href && secondaryButtonLink.value.text) || secondaryButtonLink.editable) && (
              <Stack.Item grow={false}>
                <Stretch width={{ initial: true, lg: false }}>
                  <Link
                    editable={secondaryButtonLink.editable}
                    linkType={secondaryButtonLink.value.linktype}
                    linkValue={secondaryButtonLink.value}>
                    <ButtonLink action="secondary" target={secondaryButtonLink.value.target}>
                      {secondaryButtonLink.value.text}
                    </ButtonLink>
                  </Link>
                </Stretch>
              </Stack.Item>
            )}
          </Stack>
        )}
        {((link.value.href && link.value.text) || link.editable) && (
          <Stack.Item grow={false}>
            <Link editable={link.editable} linkType={link.value.linktype} linkValue={link.value}>
              <TextLink emphasis="high" target={link.value.target}>
                {link.value.text}
              </TextLink>
            </Link>
          </Stack.Item>
        )}
      </Stack>
    </Box>
  );

  return (
    <Grid columns={{ initial: '1', lg: '12' }}>
      <Grid.Item gridColumn={{ initial: '1/-1', lg: '1/11' }} gridRow={{ lg: '1 / -1' }} order={{ lg: 2 }}>
        {(title.value || title.editable) && (
          <BrandBox
            title={title.value}
            borderRadius="all"
            padding={{ initial: '6', lg: '8' }}
            size={{ initial: 'M', lg: 'L' }}
          />
        )}
      </Grid.Item>

      <Grid.Item gridColumn={{ initial: '1/-1', lg: '1/13' }}>
        <Bleed top={{ initial: '0', lg: '6' }}>
          <Box paddingLeft={{ initial: '0', lg: '8' }}>
            {isDesktop ? (
              <Card corners="rounded" overflow="hidden">
                <ContentBlock
                  content={content}
                  buttonLink={buttonLink}
                  link={link}
                  placeholders={placeholders}
                  secondaryButtonLink={secondaryButtonLink}
                  uspList={uspList}
                />
                <Footer footerLink={footerLink} footerText={footerText} {...params} />
              </Card>
            ) : (
              <>
                <ContentBlock
                  content={content}
                  buttonLink={buttonLink}
                  link={link}
                  placeholders={placeholders}
                  secondaryButtonLink={secondaryButtonLink}
                  uspList={uspList}
                />
                <Footer footerLink={footerLink} footerText={footerText} {...params} />
              </>
            )}
          </Box>
        </Bleed>
      </Grid.Item>
    </Grid>
  );
};

export default HeroCardRebranded;
