import { FC } from 'react';

import { Box } from '@sparky';

import { BrandBoxTitle } from './BrandBoxTitle';
import type { BrandBoxProps } from './types';
import { getBorderRadiusProps } from './utils';

const BrandBox: FC<BrandBoxProps> = ({ title, borderRadius = 'all', size = 'L', padding = '6' }) => (
  <Box backgroundColor="backgroundBrand" padding={padding} {...getBorderRadiusProps(borderRadius)}>
    <BrandBoxTitle title={title} size={size} />
  </Box>
);

export default BrandBox;
