import type { BorderRadiusOption } from './types';

export const getBorderRadiusProps = (borderRadius: BorderRadiusOption) => {
  const radius = 'm' as const;

  switch (borderRadius) {
    case 'all':
      return { borderRadius: radius };
    case 'top':
      return {
        borderTopLeftRadius: radius,
        borderTopRightRadius: radius,
      };
    case 'bottom':
      return {
        borderBottomLeftRadius: radius,
        borderBottomRightRadius: radius,
      };
    case 'left':
      return {
        borderTopLeftRadius: radius,
        borderBottomLeftRadius: radius,
      };
    case 'right':
      return {
        borderTopRightRadius: radius,
        borderBottomRightRadius: radius,
      };
    case 'none':
    default:
      return {};
  }
};
